<template>
  <div
    class="ticker-tape box-content w-screen overflow-hidden pl-[100vw] text-white/80"
    :class="[
      useTheme(slice.primary.theme || 'Blue'),
      slice.primary.size === 'Big' ? 'big h-[15vh] text-[8vh]' : 'h-[8vh] text-[3vh]',
    ]"
  >
    <!-- animate-bigticker -->
    <div class="box-content inline-block h-full animate-bigticker whitespace-nowrap pr-[100vw]">
      <div
        v-for="(item, index) in slice.primary.items"
        :key="index"
        class="group inline-block h-full px-[8vw] transition-all"
      >
        <component
          :is="item.link.link_type != 'Any' ? 'a' : 'div'"
          ref="item"
          :href="prismic.asLink(item.link)"
        >
          <div
            class="flex h-full select-none items-center"
            :class="item.link.link_type != 'Any' ? 'group-hover:text-charcoal-100/80' : ''"
          >
            {{ item.message }}
            <ArrowRight
              v-if="item.link.link_type != 'Any'"
              class="*:fill-white/80 group-hover:*:fill-charcoal-100"
              :class="slice.primary.size === 'Big' ? 'w-[6.5vh] ml-[4vh]' : 'w-[3vh] ml-[2vh]'"
            />
          </div>
        </component>
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import type { Content } from "@prismicio/client";
import ArrowRight from "~/assets/svgs/arrow_right.svg";

const prismic = usePrismic();

// The array passed to `getSliceComponentProps` is purely optional.
// Consider it as a visual hint for you when templating your slice.
const props = defineProps(
  getSliceComponentProps<Content.TickerTapeSlice>([
    "slice",
    "index",
    "slices",
    "context",
  ]),
);
</script>
